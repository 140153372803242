import React, { useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./tabbed-hero-content-slider.module.scss"

import { HeroContent } from "../HeroContent"
import { HeroTab } from "./TabbedHero-types"
import { useTabbedContainer } from "contexts/components/TabbedContainerContext"

interface TabbedHeroContentSliderProps {}

export const TabbedHeroContentSlider = ({}: TabbedHeroContentSliderProps) => {
  const [nav1, setNav1] = useState<Slider>()
  const [nav2, setNav2] = useState<Slider>()
  const [slides, setSlidesIndexes] = useState<{ old: number; active: number }>({
    old: 0,
    active: 0,
  })
  const { allContent, handleChangeActiveTab } = useTabbedContainer<HeroTab>()

  const tabSettings = {
    arrows: false,
    beforeChange: (currentIndex: number, nextIndex: number) => {
      setSlidesIndexes({ old: currentIndex, active: nextIndex })
      handleChangeActiveTab(nextIndex)
    },
    className: "fr-tabbed-hero-content-slider__tabs",
    dots: false,
    dotsClass: "slick-dots fr-tabbed-hero-content-slider__slick-dots",
    focusOnSelect: true,
    initialSlide: 0,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    swipeToSlide: true,
  }

  const contentSettings = {
    arrows: false,
    className: "fr-tabbed-hero-content-slider__content",
    dots: false,
    dotsClass: "slick-dots fr-tabbed-hero-content-slider__slick-dots",
    fade: true,
    initialSlide: 0,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    swipeToSlide: true,
  }

  return (
    <>
      <div className="fr-tabbed-hero-content-slider__tabs-wrapper">
        <Slider
          asNavFor={nav2}
          {...tabSettings}
          ref={slider1 => slider1 && setNav1(slider1)}
        >
          {allContent.map(({ elements, id }, i) => {
            if (!elements) return
            const { tab_title } = elements

            return (
              <div
                className={`button--hero-tab
                ${slides.active === i ? " button--hero-tab--active" : ""}
                fr-tabbed-hero-content-slider__tab`}
                key={id}
              >
                <strong>{tab_title?.value}</strong>
              </div>
            )
          })}
        </Slider>
      </div>
      <div className="fr-tabbed-hero-content-slider__content-wrapper">
        <Slider
          asNavFor={nav1}
          {...contentSettings}
          ref={slider2 => slider2 && setNav2(slider2)}
        >
          {allContent.map(({ elements, id }) => {
            if (!elements) return

            const {
              buttons,
              heading,
              components_override,
              secondary_image,
              sub_heading,
              tagline,
            } = elements

            const highlights = components_override?.modular_content?.filter(
              component => component?.system?.type === "highlights_group"
            )[0]?.elements?.highlights?.modular_content

            return (
              <HeroContent
                baseClassName="fr-hero__banner-content"
                buttons={buttons?.value}
                heading={heading?.value}
                highlights={highlights}
                key={id}
                secondaryImage={secondary_image?.value[0]}
                subHeading={sub_heading}
                tagline={tagline}
              />
            )
          })}
        </Slider>
      </div>
    </>
  )
}
