import React from "react";
import { Banner } from "../HeroBanner-types";
import "components/hero/hero-common.module.scss";
import "./generic-hero.module.scss";

import { useColorAreaSettings } from "hooks/useColorAreaSettings";
import { getBackgroundImageUrlByBreakpoint } from "assets/helper/getBackgroundImageUrlByBreakpoint";
import { HeroContent } from "components/hero/HeroContent";

interface HeroProps {
  banner: Banner
}

export const GenericHero = ({ banner }: HeroProps) => {
  const {
    background_image,
    buttons,
    heading,
    sub_heading,
  } = banner;

  const prefix: string = 'fr-generic-hero';

  const { textBannerBackgroundColor, imageBannerColorOverlay } = useColorAreaSettings();
  const backgroundImageCSSVariable = background_image?.value[0]?.url
    ? getBackgroundImageUrlByBreakpoint({
        variableName: "hero-background-image",
        imageUrl: background_image.value[0].url,
        breakpoints: {
          mobile: {
            width: 768,
          },
        },
      })
    : {};

  const isTextOnly = background_image && background_image.value.length > 0
    ? false
    : true;
  const bannerColorOverride = textBannerBackgroundColor
    ? ` ${textBannerBackgroundColor}--backgroundColor`
    : "";
  const overrideBackground = imageBannerColorOverlay
    ? ` ${imageBannerColorOverlay}--backgroundOverlayBefore`
    : "";

  return (
    <section
      className={`${prefix} ${
        isTextOnly
          ? `${prefix}--default-background-color${bannerColorOverride}`
          : `${prefix}--background-image`
      }${overrideBackground}`}
      style={ backgroundImageCSSVariable }
    >
      <div
        className={ `${prefix}__banner-content fr-container fr-container—large` }
      >
        <HeroContent
          baseClassName="fr-generic-hero__banner-content"
          buttons={ buttons?.value }
          heading={ heading?.value }
          subHeading={ sub_heading }
        />
      </div>
    </section>
  )
}
