import React from "react";

import { useColorAreaSettings } from "hooks/useColorAreaSettings";
import { useGeneralSettings } from "hooks/useGeneralSettings";
import { useLogos } from "hooks/queries/settings/useLogos";
import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import { useWindowDimensions } from "hooks/useWindowDimensions";

import { ImageBuilder } from "_types/AssetsImage";

import { HeroTab } from "./TabbedHero-types";
import { Tabs } from "components/tabs/Tabs";
import { TabbedHeroContentSlider } from "./TabbedHeroContentSlider";

interface TabbedHeroBannerContentProps {
  contentClassName: string;
	handleActiveTabContent: (activeTabContent: HeroTab) => JSX.Element;
	handleGetHeroBgImageUrl: (
    activeTabContent?: HeroTab
  	) => Record<string, string>;
  heroClassName: string;
  secondaryImage?: ImageBuilder;
}

export const TabbedHeroBannerContent = ({
  contentClassName,
  handleActiveTabContent,
  handleGetHeroBgImageUrl,
  heroClassName,
  secondaryImage
}: TabbedHeroBannerContentProps) => {
  const { activeItem } = useTabbedContainer<HeroTab>();
  const { companyName } = useGeneralSettings();
  const { sideNavLogo } = useLogos();
  const { size } = useWindowDimensions();
  const { imageBannerColorOverlay } = useColorAreaSettings();

  const overrideBackground = imageBannerColorOverlay
    ? ` ${imageBannerColorOverlay}--backgroundOverlayBefore`
    : "";

  const ariaLabelledBy = `tab-${activeItem.elements?.tab_title?.value.replaceAll(
    " ",
    "_"
  )}-${activeItem.id}`;

  const tabPanelId = `tabpanel-${activeItem.elements?.tab_title?.value.replaceAll(
    " ",
    "_"
  )}-${activeItem.id}`;

  return (
    <section
      className={ `fr-hero${heroClassName}${overrideBackground}` }
      style={ handleGetHeroBgImageUrl(activeItem) }
    >
      <div
        className={ `${contentClassName} ${contentClassName}--tabbed-version fr-container fr-container--large` }
      >
        { !secondaryImage && sideNavLogo && (
          <img
            alt={ sideNavLogo.description || `${companyName} Logo` }
            className="fr-hero__logo"
            loading="lazy"
            src={ sideNavLogo.url }
          />
        ) }
        { size >= 1024 && (
          <>
            <Tabs buttonType="hero" className="fr-hero-tabs-controller" />
            { activeItem && (
              <div
                aria-labelledby={ ariaLabelledBy }
                id={ tabPanelId }
                role="tabpanel"
              >
                { handleActiveTabContent(activeItem) }
              </div>
            ) }
          </>
        ) }
        { size > 0 && size <= 1023 && <TabbedHeroContentSlider /> }
      </div>
    </section>
  )
}
