import React from "react"
import { getPhoneIcon } from "assets/helper/icons"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { formatPhoneNumber } from "assets/helper/formatPhoneNumber";

interface AnchorNumberProps {
  phoneNumber: string
  trackingNumber: string
  label?: string
  svgIcon?: string
  classNameModifier?: string
  showIcon?: boolean
}

export const AnchorNumber = ({
  phoneNumber,
  trackingNumber,
  label,
  svgIcon,
  classNameModifier = "",
  showIcon = true,
}: AnchorNumberProps) => {
  const { usePhoneNumberNotTrackingNumber } = useGeneralSettings()

  const formattedPhone = usePhoneNumberNotTrackingNumber
    ? formatPhoneNumber(phoneNumber)
    : formatPhoneNumber(trackingNumber)

  return (
    <a
      href={`tel: ${
        usePhoneNumberNotTrackingNumber ? phoneNumber : trackingNumber
      }`}
      className={`button--outline-secondary form-stub-banner__phone-link${classNameModifier}`}
    >
      {label && <span className="form-stub-banner__extra-call-text">Call</span>}
      {showIcon && svgIcon && (
        <span
          dangerouslySetInnerHTML={{ __html: svgIcon }}
          contentEditable="false"
          className="phone-svg-container"
        />
      )}
      {showIcon && !svgIcon && <>{getPhoneIcon()}</>}
      <span className="form-stub-banner__phone-link--text">
        {formattedPhone}
      </span>
    </a>
  )
}
