import React, {useState}from "react"
import { AnchorNumber } from "./AnchorNumber"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { FormStub } from "./FormStub"
import { graphql } from "gatsby"
import { KontentFormStubBannerFormatted } from "./formStubBanner-types"
import "./form-stub.module.scss"
import { useLayout } from "contexts/components/LayoutContext"
import { useTrackingNumberModal } from "hooks/useTrackingNumberModal"
import { TrackingNumberModal } from "components/modal/TrackingNumberModal/TrackingNumberModal"
import { getPhoneIcon } from "assets/helper/icons"
import { Button } from "components/button"
interface StubProps {
  formStub: KontentFormStubBannerFormatted
  nextBlockHasHeading?: boolean
}

export const StubBanner = ({ formStub, nextBlockHasHeading }: StubProps) => {
  const { heading, buttons, subheading, fields } = formStub
  const marginBottomModifier = nextBlockHasHeading
  const { slug } = useLayout()
  const [openModal, setModal] = useState(false)
  const isContactPage = slug.includes("contact")
  const {
    trackingNumbers,
    customTrackingNumberCtaButtonText,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()
  const trackingNumberCTAText =
  customTrackingNumberCtaButtonText || "Call Today! "
  const PhoneLink = () => {
    const { trackingNumber, phoneNumber, id } = trackingNumbers[0]
    if (usePhoneNumberNotTrackingNumber && !phoneNumber) return <></>
    if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return <></>
    return (
      <AnchorNumber
        key={id}
        phoneNumber={phoneNumber}
        trackingNumber={trackingNumber}
        showIcon={false}
        label={trackingNumberCTAText}
      />
    )
  }

  return (
    <>
    { openModal &&
    <TrackingNumberModal customState={setModal}></TrackingNumberModal>
}
    <section className={`form-stub-banner ${marginBottomModifier}`}>
      <div className="fr-container fr-container--large">
        <div className="form-stub-banner-container">
          <div className="form-stub-banner__heading-container">
            <h2 className="form-stub-banner__heading">{heading}</h2>
            <p className="form-stub-banner__subheading">{subheading}</p>
          </div>
          {isContactPage ? (
            <>
            {trackingNumbers.length >= 2 ?
          (<>
          <Button
              variant="flashy-icon"
              onClick={() => setModal(true)}
              extraClassNames="button--outline-secondary form-stub-banner__phone-link"
            >
              {trackingNumberCTAText}
            </Button>
            {openModal &&
              trackingNumbers.map(
                ({ trackingNumber, phoneNumber, id, label }) => {
                  if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
                  if (!usePhoneNumberNotTrackingNumber && !trackingNumber)
                    return ""
                  return (
                    <AnchorNumber
                      key={id}
                      classNameModifier="--hidden"
                      phoneNumber={phoneNumber}
                      trackingNumber={trackingNumber}
                      label={label}
                      showIcon={false}
                    />
                  )
                }
              )}
          </>):
          (<PhoneLink />)
          }
            </>

          ) : (
            <FormStub fields={fields} buttons={buttons} />
          )}
        </div>
      </div>
    </section>
    </>
  )
}

export const query = graphql`
  fragment FormStubBannerFragment on kontent_item_form_stub_banner {
    id
    elements {
      icon {
        value {
          ...KenticoAssetElementFragment
        }
      }
      heading {
        value
      }
      subheading {
        value
      }
      fields {
        value {
          ...FormFieldFragment
        }
      }
      button {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
