import React, { useContext } from "react"
import "./form-stub.module.scss"
import { FormStubField } from "./formStubBanner-types"
import { ButtonParser } from "components/button/ButtonParser"
import { ButtonBuilder } from "_types/Button"
import { FormFieldsContext } from "contexts/FormFieldsContext"

interface FormStubProps {
  buttons: ButtonBuilder[]
  fields?: FormStubField[]
}

export const FormStub = ({ fields, buttons }: FormStubProps) => {
  const { setEmailAddress } = useContext(FormFieldsContext)
  const setFields = (e: { target: HTMLInputElement }) => {
    const { value } = e?.target
    setEmailAddress(value)
  }

  return (
    <div className="form-stub">
      <div className="form-stub__field">
        {fields &&
          fields.map(({ elements }) => {
            return (
              <>
                <label htmlFor={elements?.form_field?.value[0]?.codename}>
                  {elements?.label?.value}:
                </label>

                <input
                  type={elements?.field_type?.value[0].codename}
                  name={elements?.form_field?.value[0].codename}
                  placeholder={
                    elements?.field_type?.value[0].codename === "email"
                      ? "email@example.com"
                      : "John Smith"
                  }
                  onBlur={setFields}
                />
              </>
            )
          })}
        {buttons.length > 0 && (
          <ButtonParser
            buttons={buttons}
            ctaColorVariantSolid={"solid-secondary"}
            isSingleCTAButtonAlwaysOutline={!fields || fields.length === 0}
            extraClassNames={
              !fields || fields.length === 0 ? "" : "form-stub__field--button"
            }
          />
        )}
      </div>
    </div>
  )
}
