import React, { useCallback, useMemo } from "react"
import { graphql } from "gatsby"
import "components/hero/hero-common.module.scss"
import "./tabbed-hero-banner.module.scss"

import { getBackgroundImageUrlByBreakpoint } from "assets/helper/getBackgroundImageUrlByBreakpoint"
import { CmsAssetImage } from "_types/AssetsImage"
import { HeroContent } from "../HeroContent"
import { HeroTab } from "./TabbedHero-types"
import { TabbedContainer } from "components/tabs/TabbedContainer"
import { TabbedHeroBannerContent } from "./TabbedHeroBannerContent"

interface TabbedHeroBannerProps {
  banner: {
    background_image?: CmsAssetImage
    hero_tabs: {
      modular_content: HeroTab[]
    }
    secondary_image?: CmsAssetImage
  }
}

export const TabbedHeroBanner = ({ banner }: TabbedHeroBannerProps) => {
  const { background_image, secondary_image, hero_tabs } = banner

  const hasAtleastOneSecondaryImgInTab = useMemo(
    () =>
      hero_tabs?.modular_content?.some(
        ({ elements }) => !!elements?.secondary_image_override?.value[0]
      ),
    [hero_tabs]
  )

  const hasSecondaryImage = (secondary_image && secondary_image?.value?.length > 0) ||
  hasAtleastOneSecondaryImgInTab
      ? true : false;

  const heroClassName =
    hasSecondaryImage
      ? ` fr-hero--with-image`
      : ` fr-hero--default`;

  const contentClassName =
    hasSecondaryImage
      ? 'fr-hero__banner-content-with-image'
      : 'fr-hero__banner-content';

  const getActiveTabContent = useCallback(
    (activeTabContent: HeroTab) => {
      const {
        heading,
        sub_heading,
        components_override,
        buttons,
        secondary_image_override,
        tagline,
      } = activeTabContent?.elements

      const highlights = components_override?.modular_content?.filter(
        component => component?.system?.type === "highlights_group"
      )[0]?.elements?.highlights?.modular_content

      return (
        <HeroContent
          baseClassName={contentClassName}
          buttons={buttons?.value}
          heading={heading?.value}
          highlights={highlights}
          subHeading={sub_heading}
          secondaryImage={
            secondary_image_override?.value[0] || secondary_image?.value[0]
          }
          tagline={tagline}
        />
      )
    },
    [secondary_image, contentClassName]
  )

  const handleGetHeroBgImageUrl = useCallback(
    (activeTabContent?: HeroTab) => {
      if (!activeTabContent) return {}

      const heroBackgroundImageUrl =
        activeTabContent?.elements?.background_image_override?.value[0]?.url ||
        background_image?.value[0]?.url

      const backgroundImageCSSVariable = heroBackgroundImageUrl
        ? getBackgroundImageUrlByBreakpoint({
            variableName: "hero-background-image",
            imageUrl: heroBackgroundImageUrl,
            breakpoints: {
              mobile: {
                width: 768,
              },
            },
          })
        : {}

      return backgroundImageCSSVariable
    },
    [background_image]
  )

  return (
    <TabbedContainer allContent={hero_tabs.modular_content}>
      <TabbedHeroBannerContent
        contentClassName={contentClassName}
        handleActiveTabContent={getActiveTabContent}
        handleGetHeroBgImageUrl={handleGetHeroBgImageUrl}
        heroClassName={heroClassName}
        secondaryImage={secondary_image?.value[0]}
      />
    </TabbedContainer>
  )
}

export const tabbedHeroFragment = graphql`
  fragment TabbedHeroBannerFragment on kontent_item_tabbed_hero {
    id
    elements {
      hero_tabs {
        modular_content {
          ...HeroTabFragment
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
    }
  }
`

export const heroTabFragment = graphql`
  fragment HeroTabFragment on kontent_item_hero_tab {
    id
    elements {
      tab_title {
        value
      }
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      tagline {
        value
        type
      }
      background_image_override {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image_override {
        value {
          ...KenticoAssetElementFragment
        }
      }
      components_override {
        modular_content {
          ...HighlightGroupFragment
        }
      }
      buttons {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
